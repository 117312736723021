<template>
    <div class="container" id="pgzs">
        <div class="aside" >
            <side-bar ref="sideBar" class="sidebar-container" />
        </div>

        <section class="mainContent">
            <div class="mainContainer" v-loading="loading" element-loading-text="数据加载中,请稍候...">
                <div class="chat-top">
                    <div class="chat-search-box">
                        <el-input v-model="searchVal" placeholder="search..."></el-input>
                        <span class="chat-search-icon"></span>
                    </div>
                </div>
                
                <div class="chat-mainer">
                    <div class="chat-content">
                        <div class="chat_main chat-welcome" v-if="!isShowChat">
                            <div class="welcome">
                                <div class="chat-welImg"><img :src="welData.imgUrl" /></div>
                                <h2>{{welData.title}}</h2>
                                <p>{{welData.txt}}</p>
                            </div>
                        </div>
                        <div class="chat_main" id="chat_mainer" v-else>
                            <div id="chat_main" @click="hidePlanLaws"></div>
                            <div class="tree-main">
                                <node-plan :data="planLawsData"></node-plan>
                            </div>
                        </div>
                        

                        <el-row class="chat_buttom_new">
                            <tag-tips :tipData="tipData"></tag-tips>
                            <div class="chat_buttom">
                                <div class="chat_text">
                                    <textarea class="chat_ipt" v-model="chatText" @keyup="handleChatIptEnter" placeholder="请输入您的问题" autofocus></textarea>
                                </div>
                                <div class="chat_btn_box">
                                    <div class="chat_btn" :class="chatText!=null && chatText!=''? 'active':''" @click="handleChatAdd"></div>
                                    <div class="switch_btn" @click="handleChatSwitch"><img :src="require('../../assets/icon/switch-' + (!chatSwitch?'off.png':'on.png'))"/></div>
                                </div>
                            </div>
                        </el-row>
                    </div>
                </div>
            </div>
        </section>

        <set-dialog :dialogSetVisible="dialogSetVisible"></set-dialog>
        <info-dialog :dialogSysVisible="dialogSysVisible"></info-dialog>
    </div>
</template>

<script>
import SideBar from '../../components/Sidebar/index.vue'
import SetDialog from '../../components/Sidebar/SetDialog.vue';
import InfoDialog from '../../components/Sidebar/InfoDialog.vue';
import NodePlan from '../../components/trouble/NodePlan.vue'
import TagTips from '../../components/main/TagTips.vue';
import { planAssociation } from '@/api/process'
import { knowledgeFault } from '@/api/inversion'
export default {
  name:"index",
  components: {
    SideBar,
    SetDialog,
    InfoDialog,
    NodePlan,
    TagTips
  },
  data() {
    return {
        appId: 'PGZS',
        sessionId: null,
        rereply: 0,
        question: '',
        searchVal: '',
        inversionVal: '0',
        loading: false,
        chatText: null,//聊天文本框值
        chatSwitch: false, //聊天开关
        infoLoad: false,
        visible: false, //评价框开关
        evaValue: 4, //评分
        likeTo: false, //赞
        stepOn: false, //踩
        pdfUrl: require('../../assets/icon/icon_pdf.png'),
        replyText: '',
        inversList: [],
        tabList: [], //tab列表
        activeName: 'first',
        iframeUrl: '',
        isShowChat: false,
        welData: {
            imgUrl: require('../../assets/image/pic.png'),
            title: '基于异常现象描述，通过「逻辑脑+意识脑」双驱动，进行故障归因、生成故障树，并给出处置建议',
            txt: '• 基于专家系统的决策式智能技术 • 基于思维链的生成式智能技术 • 航天故障信息编解码语言（FTPL）'
        },
        planLawsData: {
            name: '',
            caseList: []
        },
        dialogSetVisible: false,
        dialogSysVisible: false,
        tipData: []
    }
  },
  created() {
    let text = JSON.parse(localStorage.getItem('question'))
    if(text && !this.chatText) {
        this.handleChatAdd(text)
    }
    setInterval(function(){ 
        //聊天iframe撑开 
        document.querySelectorAll("iframe").forEach(function(item){
            let cm = item.contentDocument.getElementById("chat_main")
            if(cm && item.height!=cm.clientHeight){
                item.ch = cm.clientHeight
                item.height = cm.clientHeight
                
                //聊天滚动最下
                let bcm = document.getElementById("chat_mainer")
                if(bcm){
                    bcm.scrollTop = bcm.scrollHeight
                }
            }
        })
    }, 500);
  },
  mounted() {
    window.changeLoading = this.changeLoading;
    window.closeChat = this.closeChat;
    window.handleClickTree = this.handleClickTree
    window.refreshData = this.refreshData;
    window.handleText = this.handleText
    this.$refs.sideBar.handleMenuClick(2);
    this.refreshData();
  },
  methods: {
    changeLoading(loading){
        this.loading = loading
        if(!loading){
            document.querySelectorAll("iframe").forEach(function(item){
                item.style.display='block'
            })
        }
    },
    refreshData() {
        knowledgeFault({appId:this.appId,count:1}).then(res => {
            if(res && res.query_list.length>0) {
                this.tipData = res.query_list
                this.dialogSetVisible = false
                this.dialogSysVisible = false
            }
        }).catch(err => {})
    },
    handleText(text) {
        if(text) {
            this.chatText = text
        }
    },
    closeChat(){
        this.isShowChat = false
        //聊天滚动最下
        let bcm = document.getElementById("chat_main")
        if(bcm){
            bcm.innerHTML = ''
        }
    },
    queryInversion(cacheText) {
        let chat = document.getElementById('chat_main');
        let iframe = document.createElement("iframe")
        if(cacheText) {
            iframe.setAttribute("src", 'robot?appId=' + this.appId + '&question=' + this.question + '&sessionId=' + this.sessionId + '&cache=true')
        } else {
            iframe.setAttribute("src", 'robot?appId=' + this.appId + '&question=' + this.question + '&sessionId=' + this.sessionId)
        }
        iframe.setAttribute("width", '100%')
        iframe.setAttribute("frameborder", '0')
        iframe.setAttribute("allowfullscreen", 'true')
        iframe.setAttribute("scrolling", 'no')
        iframe.setAttribute("ch", 0)
        iframe.setAttribute("style", 'display: none;margin-bottom:40px')
        chat.appendChild(iframe)
    },
    handleChatAdd(cacheText){ //手写输入
        if(cacheText) {
            if(this.chatText) {
                let text = this.chatText
                if(text){
                    text = text.replace(new RegExp('\\r\\n','gm'),'')
                    text = text.replace(new RegExp('\\n','gm'),'')
                    
                    if(text!=='') {
                        this.chatText = null
                        this.isShowChat = true
                        this.question = text
                        this.$nextTick(() => {
                            this.handleAddVoiceContent(1, text);
                            this.queryInversion()
                        })
                    } else {
                        this.chatText = null
                        this.$message.warning('请输入有效内容')
                    }
                } else {
                    this.$message.warning('请输入有效内容')
                }
            } else {
                this.isShowChat = true
                this.question = cacheText
                this.$nextTick(() => {
                    this.handleAddVoiceContent(1, cacheText);
                    this.queryInversion(cacheText)
                })
            }
        } else {
            if(this.chatText==null){
                this.$message.warning('请输入有效内容')
                return
            } else {
                let text = this.chatText
                if(text){
                    text = text.replace(new RegExp('\\r\\n','gm'),'')
                    text = text.replace(new RegExp('\\n','gm'),'')
                    
                    if(text!=='') {
                        this.chatText = null
                        this.isShowChat = true
                        this.question = text
                        this.$nextTick(() => {
                            this.handleAddVoiceContent(1, text);
                            this.queryInversion()
                        })
                    } else {
                        this.chatText = null
                        this.$message.warning('请输入有效内容')
                    }
                } else {
                    this.$message.warning('请输入有效内容')
                }
            }
        }
    },
    handleChatIptEnter(event){ //手写回车
        if (event.keyCode == "13") {
            event.preventDefault() // 阻止浏览器默认换行操作
            this.handleChatAdd()
            return false
        }
    },
    handleAddVoiceContent(type, text){ //展示语音内容 type 1加载中 2展示x
      let chat = document.getElementById('chat_main');
      let item = document.querySelector('.msg-box-r')
      let logoItem = document.querySelector('.msg-l-logo')

      if(type==1){
        let box = document.createElement("div")
        box.setAttribute("class", "msg-box-r")
        chat.appendChild(box)

        item = document.createElement("div")
        item.innerHTML = text
        item.setAttribute("class", "msg-l")
        box.appendChild(item)

        logoItem = document.createElement("div")
        logoItem.setAttribute("class", "msg-l-logo")
        box.appendChild(logoItem)
        //聊天滚动最下
        let bcm = document.getElementById("chat_mainer")
        if(bcm){
            bcm.scrollTop = bcm.scrollHeight
        }
      }
    },
    handleChatSwitch(){//聊天输入方式切换
        this.chatSwitch = !this.chatSwitch
    },
    handleChangeChatText(text){ //改变聊天输入框的内容
        this.chatText = text
        let ipt = document.getElementsByClassName(".chat_ipt")[0]
        if(ipt){
            ipt.focus();
        }
    },
    handleClickTree(data) {
        this.queryLawsCard({query:data.label,page:1,size:3})
        this.planLawsData.name = data.label
        let dom = document.querySelector('.tree-main');
        dom.setAttribute("style","right:0")
    },
    hidePlanLaws() {
        let dom = document.querySelector('.tree-main');
        dom.setAttribute("style","right:-380px")
    },
    queryLawsCard(data) {
        planAssociation(data).then(res => {
            if(res) {
                if(res.datas && res.datas.length>0) {
                    res.datas.map(x => {
                        if(x.criterions && x.criterions.length>0) {
                            x.criterionName = ''
                            x.causeName = ''
                            x.solutionName = ''
                            x.criterions.map(y => {
                            x.criterionName += y.failureCriterion
                                if(y.causes && y.causes.length>0) {
                                    y.causes.map(v => {
                                    x.causeName += v.possibleCause
                                        if(v.solutions && v.solutions.length>0) {
                                            v.solutions.map(u => {
                                            x.solutionName += u.preventionSolution
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                    this.planLawsData.caseList = res.datas
                } else {
                    this.planLawsData.caseList = []
                }
            }
        }).catch(err =>{})
    }
  }
}
</script>
<style scoped>
.mainContent .chat_main {
    height: calc(100% - 162px);
}
</style>