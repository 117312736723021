<template>
    <div class="node-main">
        <div class="tree-top">{{data.name}}</div>
        <div class="process-case">
            <div class="process-case-item" v-for="(item, index) in data.caseList" :key="index">
                <div class="case-item-top">
                    <span class="case-item-title">{{item.jobContent}}</span>
                </div>
                <div class="case-item-content">
                    <ul class="case-ul">
                        <li>
                            <div class="row-title">所处时段：</div>
                            <div class="row-txt">
                            <el-tooltip v-if="item.timeFrame && item.timeFrame.length>16" class="item" effect="dark" :content="item.timeFrame" placement="top-start">
                                <el-button>{{item.timeFrame}}</el-button>
                            </el-tooltip>
                            <span if-else>{{item.timeFrame}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">系统：</div>
                            <div class="row-txt">
                            <el-tooltip v-if="item.systemName && item.systemName.length>16" class="item" effect="dark" :content="item.systemName" placement="top-start">
                                <el-button>{{item.systemName}}</el-button>
                            </el-tooltip>
                            <span if-else>{{item.systemName}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">故障判据：</div>
                            <div class="row-txt">
                            <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                <div slot="content">
                                <div v-for="criter in item.criterions" :key="criter.criterionId">{{criter.failureCriterion}}</div>
                                </div>
                                <el-button>{{item.criterionName}}</el-button>
                            </el-tooltip>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">可能原因：</div>
                            <div class="row-txt">
                            <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                <div slot="content">
                                <div v-for="criter in item.criterions" :key="criter.criterionId">
                                    <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                                </div>
                                </div>
                                <el-button class="clamp2">{{item.causeName}}</el-button>
                            </el-tooltip>
                            <span v-else v-for="criter in item.criterions" :key="criter.criterionId">
                                <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                            </span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">解决方案：</div>
                            <div class="row-txt clamp3">
                                <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                    <div slot="content">
                                    <div v-for="criter in item.criterions" :key="criter.criterionId">
                                        <div v-for="cause in criter.causes" :key="cause.causeId">
                                        <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                        </div>
                                    </div>
                                    </div>
                                    <el-button class="clamp3">{{item.solutionName}}</el-button>
                                </el-tooltip>
                                <span v-else v-for="criter in item.criterions" :key="criter.criterionId">
                                    <span v-for="cause in criter.causes" :key="cause.causeId">
                                    <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name:"NodePlan",
    props: {
      data: {type: Object, required: true}
    },
    data(){
      return {}
    },
    methods: {

    }
  } 
</script>

<style scoped>
.tree-top {
    background-color: rgba(77,130,255, .3);
    height: 40px;
    padding-left: 10px;
    line-height: 40px;
    border-radius: 20px 20px 0 0;
}
.process-case {
    padding: 0 15px;
}
.process-case .process-case-item {
  background-color: rgba(255,255,255,.7);
  border-radius: 5px;
  width: 100%;
  margin: 15px 0 0 0;
}
.process-case-item .case-item-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 15px;
  background-color: #edf2ff;
  border: 1px solid #dde4fd;
  border-bottom: none;
  height: 36px;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
}
.process-case-item .case-item-top .case-item-title {
    width: 95%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.process-case-item .case-item-top.exist {
  background-color: #dae9ed;
  border: 1px solid #eaf7f0;
}

.process-case-item .case-item-content {
  border: 1px solid #edeffd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 10px 15px;
  font-size: 12px;
  height: calc(100% - 36px);
}
.case-item-content .case-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.case-item-content .case-ul li {
  padding-bottom: 5px;
  overflow: hidden;
}
.case-item-content .case-ul li:last-child {
  padding-bottom: 0;
}
.case-ul .row-title {
  float: left;
  width: 60px;
  color: #999;
}
.case-ul .row-txt {
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.case-item-content .case-ul .el-button {
    font-size: 12px;
    background-color: transparent;
    padding: 0;
    border: none;
    color: #333;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    cursor: default;
    line-height: 18px;
}
.case-item-content .case-ul .el-button:hover {
  border-color: transparent;
  background-color: transparent;
}
.case-item-content .case-ul .clamp2 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 36px;
  display: grid;
}
.case-item-content .case-ul .clamp3 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 50px;
  display: grid;
}
</style>